// import logo from '../logo.svg';
import './App.css';
import './nav/nav'
import NavBaritem from './nav/nav';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';

function App() {
  return (
    <Container>
    <NavBaritem />
    </Container>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       React from Main FOlder
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://nellaicomputers.com"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
